.App {
  background-color: #bdc3c7;
  margin: 0px;
  min-height: 100vh;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0px;
}