.twitter-tweet {
  display: inline-block;
  background-color: #e6ecf0;
  color: black;
  text-align: left;
  font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  border-color: #eee #ddd #bbb;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 10px 0px;
  padding: 16px;
  width: 100%;
  max-width: 468px;
}

.twitter-tweet img {
  margin: 5px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.tweet-header {
  display: flex;
  justify-content: left;
}

.tweeter-name-box {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: center;
}

.tweeter-username {
  color: rgb(99, 99, 99);
}

.twitter-tweet p {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}

.tweet-display-area {
  display: flex;
  flex-direction: column;
}
